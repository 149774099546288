export default {
  investment: {
    p1: 'Your terms of the relationship.',
    wrapper_title: 'Work with us the way you want.',
    textare1: "Some people think you have to choose between an online broker and a wealth manager. At the Global Club, you don't need to compromise.",
    textare2: 'Whether you invest on your own, with an adviser, or both - we can support you.',
    info_title1: 'Invest',
    info_span1: 'A wide selection of investment products to help build a diversified portfolio',
    info_title2: 'Trading',
    info_span2: 'Superior trading tools, resources and support',
    info_title3: 'Wealth management',
    info_span3: 'Dedicated financial advisors help you reach your own specific goals',
    info_title4: 'Investment consulting',
    info_span4: 'Experienced portfolio managers can choose from a wide range of investment strategies',
    info_title5: 'Intelligent combination',
    info_span5: 'A revolutionary fully automated investment advisory service',
    info_title6: 'Consultant network',
    info_span6: 'Independent local advisors provide professional guidance to high net worth investors',
    footer_text: 'Our program is for those who want to improve their financial situation, but do not have the foundation to do so and do not have experience in the financial world. Our team has been engaged in hedging trading for many years and has achieved good results and stable earnings during this time. In order to further develop and increase trading volume, we have a need for external investment. We engage in a wide range of financial activities, primarily focused on high-yield corporate and bank promissory notes. These are relatively rare financial products that few financial institutions and individuals have access to. Corporate and bank promissory notes are fairly low-risk investments compared to other high-yield investment opportunities, as the transactions involving these notes are backed by major banks. We recommend that you become a member of our team under mutually beneficial conditions. Unlike other projects, we do not promise quick profits with minimal investment.'
  }   
}