export default {
  home: {
    notWait: '別等。',
    openAccountToday: '今天就開設賬戶。',
    openAccount: '開設賬戶',
    GCGMarkets: '環球星匯',
    lowRisk: '低風險 帶保險 放心交易',
    exclusive: '獨家尖端高科技',
    trading: '交易倉保險倉完美操作',
    realize: '實現您的交易夢想',
    wrapper_title1: '許多投資方式，一個地方實踐。',
    drc_invest_title: '價值投資',
    drc_invest_span: '低交易費用和定價，沒有隱藏的費用',
    drc_products_title: '全套產品',
    drc_products_span: '交易外匯/貴金屬',
    drc_tools_title: '超能交易工具',
    drc_tools_span: '將交易能力帶到指尖的平臺',
    drc_partner_title: '與顧問一起工作',
    drc_partner_span: '獲得專業幫助建立個性化的財務計劃',
    register: '註冊',
    bestbroker_span1_1: '2018 最佳在線經紀商',
    bestbroker_wrapper_title1: '如何 開始外匯交易',
    bestbroker_span4: '您需要註冊一個外匯賬戶，然後開始交易。通過比較，找到一個迎合您需要外匯交易賬戶。外匯交易使用貨幣對；第一個貨幣是基礎貨幣，第二個貨幣是反貨幣。您有兩個選擇去創造盈利：',
    bestbroker_span5: '買：如果您認爲基礎貨幣將升值超過反貨幣。',
    bestbroker_span6: '賣：如果您認爲反貨幣會比基礎貨幣升值更多。',
    bestbroker_span1_2: '按照您想要的方式和我們一起合作。',
    bestbroker_wrapper_title2: '您的條款的關係。',
    bestbroker_text3: '有人認爲您必須選擇在線經紀人和財富管理公司。 在環球星匯，您不需要妥協。無論您是自己投資，還是顧問，還是兩者兼顧 - 我們都可以爲您提供支持。',
    bestbroker_info_title1: '投資',
    bestbroker_info_span1: '投資產品的廣泛選擇，以幫助建立多元化的投資組合',
    bestbroker_info_title2: '貿易',
    bestbroker_info_span2: '超強交易工具，資源和支持',
    bestbroker_info_title3: '財富管理',
    bestbroker_info_span3: '專門的財務顧問幫助達到您自己的具體目標',
    bestbroker_info_title4: '投資諮詢',
    bestbroker_info_span4: '經驗豐富的投資組合經理可以選擇廣泛的投資策略',
    bestbroker_info_title5: '智能組合',
    bestbroker_info_span5: '革命性的全自動投資諮詢服務',
    bestbroker_info_title6: '顧問網絡',
    bestbroker_info_span6: '獨立的當地顧問爲高淨值投資者提供專業指導',
    download_wrapper_title: '憑藉我們的強大平臺進入市場。',
    download_text2: '我們的旗艦交易平臺，專爲各種交易者設計。',
    download_text3: '通過最強大和便利的平臺，從您的桌面或流動工具於任何平臺交易。',
    download_info_title1: 'WINDOWS下載',
    download_info_title2: 'OS X 和 IOS下載',
    download_info_title3: '安卓下載',
    knowledge_wrapper_title: '知識賦予您力量',
    knowledge_info_title1: '初學者課程',
    knowledge_info_span1: '學習外匯交易的基本概念、這個市場的概括和您理應成爲一份子的原因。',
    knowledge_info_title2: '交易工具',
    knowledge_info_span2: '熟悉先進的戰略和MT4交易工具。把您的交易提高到一個新的水平。',
    knowledge_info_title3: '技術分析',
    knowledge_info_span3: '技術分析使用圖表模式和技術來預測未來的價格變動。',
    knowledge_info_title4: '基本分析',
    knowledge_info_span4: '基本分析師考慮所有可用的數據來幫助他們確定市場的相對價值。',
    knowledge_info_title5: '風險管理',
    knowledge_info_span5: '瞭解爲什麼您應該把重點放在頭寸大小，以及爲什麼風險管理是交易成功的關鍵。',
  }  
}