export default {
  education: {
    p1: 'Knowledge empowers you',
    wrapper_title: 'self-investment',
    wrapper_sub_title: 'Knowledge is a wise investment',
    p2: 'We always believe that the more you know, the better you will be. By combining easy-to-understand information with actionable insights, GCG MARKETS helps make markets seem less intimidating and more accessible.',
    info_title1: 'Beginner course',
    info_span1: 'Learn the basic concepts of Forex trading, an overview of the market and why you should be a part of it.',
    info_title2: 'Trading instrument',
    info_span2: "Get familiar with advanced strategies and Xtrade's trading tools to take your trading to the next level.",
    info_title3: 'Stocks and CFDS',
    info_span3: 'Explore the world of CFD trading: Information about the CFD market and market dynamics.',
  }  
}