export default {
  investment: {
    p1: '您的条款的关系。',
    wrapper_title: '按照您想要的方式和我们一起工作。',
    textare1: '有人认为您必须在在线经纪人和财富管理公司之间做出选择。在环球星汇，您不需要妥协。',
    textare2: '无论您是自己投资，还是由顾问投资，还是两者兼顾 - 我们都可以为您提供支持。',
    info_title1: '投资',
    info_span1: '投资产品的广泛选择，以帮助建立多元化的投资组合',
    info_title2: '交易',
    info_span2: '超强的交易工具，资源和支持',
    info_title3: '财富管理',
    info_span3: '专门的财务顾问帮助达到您自己的具体目标',
    info_title4: '投资咨询',
    info_span4: '经验丰富的投资组合经理可以选择广泛的投资策略',
    info_title5: '智能组合',
    info_span5: '革命性的全自动投资咨询服务',
    info_title6: '顾问网络',
    info_span6: '独立的当地顾问为高净值投资者提供专业指导',
    footer_text: '我们的计划是为那些想改善他们的财务状况， 但却没有这方面的基础，而也不是在金融界有经验的人而设立的。我们的团队从事对冲交易多年，在此期间取得了良好的业绩和稳定的收益。为了进一步发展和增加交易量，我们对外部投资有需求。我们从事广泛的金融活动，主要集中在高收益公司和银行本票上。这些都是相对少见的金融产品，很少有金融机构和个人可以使用。与其他高收益投资机会相比，公司和银行本票是风险相当低的投资，因为涉及这些期票的交易由主要银行提供支持。我们建议您在互利的条件下成为我们团队的一员。与其他项目不同，我们不承诺以最低投资实现快速盈利。'
  }  
}