export default {
  education: {
    p1: '知识赋予您力量',
    wrapper_title: '自我投资',
    wrapper_sub_title: '知识是明智的投资',
    p2: '我们一直坚信您认知得越多，将成就更好的你。通过简易理解的信息与可执行的见解互相结合，环球星汇有助于使市场看起来不那么令人生畏，并更易被接受。',
    info_title1: '初学者课程',
    info_span1: '学习外汇交易的基本概念、这个市场的概括和您理应成为一份子的原因。',
    info_title2: '交易工具',
    info_span2: '熟悉先进的策略和Xtrade的交易工具， 使您的交易提升至另一个新层次。',
    info_title3: '股票和差价合约',
    info_span3: '探索差价合约交易的世界：差价合约市场的相关信息和市场动态。',
  }  
}