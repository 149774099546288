export default {
  contact: {
    p1: '知識賦予您力量',
    wrapper_title1: '按照您想要的方式和我們一起工作。',
    p2: '在環球星匯，我們會一直協助和指導您，盡我們所能幫助您邁向成功。我們的客戶支援團隊將每週5天，每天24小時爲你提供服務。',
    info_title1: '投資者關係',
    info_span1: '欲諮詢現有或潛在的有限合夥人/投資者的信息',
    info_title2: '公共關係',
    info_span2: '欲諮詢新聞，演講或營銷相關的信息',
    info_title3: '商業計劃書提交',
    info_span3: '欲提交商業計劃',
    email_btn: '請電郵至： info@gcg-markets.com'
  }  
}