export default {
  home: {
    notWait: '别等。',
    openAccountToday: '今天就开设账户。',
    openAccount: '开设账户',
    GCGMarkets: '环球星汇',
    lowRisk: '低风险 带保险 放心交易',
    exclusive: '独家尖端高科技',
    trading: '交易仓保险仓完美操作',
    realize: '实现您的交易梦想',
    wrapper_title1: '许多投资方式，一个地方实践。',
    drc_invest_title: '价值投资',
    drc_invest_span: '低交易费用和定价，没有隐藏的费用',
    drc_products_title: '全套产品',
    drc_products_span: '交易外汇/贵金属',
    drc_tools_title: '超能交易工具',
    drc_tools_span: '将交易能力带到指尖的平台',
    drc_partner_title: '与顾问一起工作',
    drc_partner_span: '获得专业帮助建立个性化的财务计划',
    register: '注册',
    bestbroker_span1_1: '2018 最佳在线经纪商',
    bestbroker_wrapper_title1: '如何 开始外汇交易',
    bestbroker_span4: '您需要注册一个外汇账户，然后开始交易。通过比较，找到一个迎合您需要外汇交易账户。外汇交易使用货币对；第一个货币是基础货币，第二个货币是反货币。您有两个选择去创造盈利：',
    bestbroker_span5: '买：如果您认为基础货币将升值超过反货币。',
    bestbroker_span6: '卖：如果您认为反货币会比基础货币升值更多。',
    bestbroker_span1_2: '按照您想要的方式和我们一起合作。',
    bestbroker_wrapper_title2: '您的条款的关系。',
    bestbroker_text3: '有人认为您必须选择在线经纪人和财富管理公司。 在环球星汇，您不需要妥协。无论您是自己投资，还是顾问，还是两者兼顾 - 我们都可以为您提供支持。',
    bestbroker_info_title1: '投资',
    bestbroker_info_span1: '投资产品的广泛选择，以帮助建立多元化的投资组合',
    bestbroker_info_title2: '贸易',
    bestbroker_info_span2: '超强交易工具，资源和支持',
    bestbroker_info_title3: '财富管理',
    bestbroker_info_span3: '专门的财务顾问帮助达到您自己的具体目标',
    bestbroker_info_title4: '投资咨询',
    bestbroker_info_span4: '经验丰富的投资组合经理可以选择广泛的投资策略',
    bestbroker_info_title5: '智能组合',
    bestbroker_info_span5: '革命性的全自动投资咨询服务',
    bestbroker_info_title6: '顾问网络',
    bestbroker_info_span6: '独立的当地顾问为高净值投资者提供专业指导',
    download_wrapper_title: '凭借我们的强大平台进入市场。',
    download_text2: '我们的旗舰交易平台，专为各种交易者设计。',
    download_text3: '通过最强大和便利的平台，从您的桌面或流动工具于任何平台交易。',
    download_info_title1: 'WINDOWS下载',
    download_info_title2: 'OS X 和 IOS下载',
    download_info_title3: '安卓下载',
    knowledge_wrapper_title: '知识赋予您力量',
    knowledge_info_title1: '初学者课程',
    knowledge_info_span1: '学习外汇交易的基本概念、这个市场的概括和您理应成为一份子的原因。',
    knowledge_info_title2: '交易工具',
    knowledge_info_span2: '熟悉先进的战略和MT4交易工具。把您的交易提高到一个新的水平。',
    knowledge_info_title3: '技术分析',
    knowledge_info_span3: '技术分析使用图表模式和技术来预测未来的价格变动。',
    knowledge_info_title4: '基本分析',
    knowledge_info_span4: '基本分析师考虑所有可用的数据来帮助他们确定市场的相对价值。',
    knowledge_info_title5: '风险管理',
    knowledge_info_span5: '了解为什么您应该把重点放在头寸大小，以及为什么风险管理是交易成功的关键。',
  }  
}