export default {
  education: {
    p1: '知識賦予您力量',
    wrapper_title: '自我投資',
    wrapper_sub_title: '知識是明智的投資',
    p2: '我們一直堅信您認知得越多，將成就更好的你。通過簡易理解的信息與可執行的見解互相結合，環球星匯有助於使市場看起來不那麼令人生畏，並更易被接受。 MARKETS有助于使市场看起来不那么令人生畏，并更易被接受。',
    info_title1: '初學者課程',
    info_span1: '學習外匯交易的基本概念、這個市場的概括和您理應成爲一份子的原因。',
    info_title2: '交易工具',
    info_span2: '熟悉先進的策略和Xtrade的交易工具， 使您的交易提升至另一個新層次。',
    info_title3: '股票和差價合約',
    info_span3: '探索差價合約交易的世界：差價合約市場的相關信息和市場動態。',
  }  
}