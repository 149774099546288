export default {
  investment: {
    p1: '您的條款的關係。',
    wrapper_title: '按照您想要的方式和我們一起工作。',
    textare1: '有人認爲您必須在在線經紀人和財富管理公司之間做出選擇。在環球星匯，您不需要妥協。',    
    textare2: '無論您是自己投資，還是由顧問投資，還是兩者兼顧 - 我們都可以爲您提供支持。',
    info_title1: '投資',
    info_span1: '投資產品的廣泛選擇，以幫助建立多元化的投資組合',
    info_title2: '交易',
    info_span2: '超強的交易工具，資源和支持',
    info_title3: '財富管理',
    info_span3: '專門的財務顧問幫助達到您自己的具體目標',
    info_title4: '投資諮詢',
    info_span4: '經驗豐富的投資組合經理可以選擇廣泛的投資策略',
    info_title5: '智能組合',
    info_span5: '革命性的全自動投資諮詢服務',
    info_title6: '顧問網絡',
    info_span6: '獨立的當地顧問爲高淨值投資者提供專業指導',
    footer_text: '我們的計劃是爲那些想改善他們的財務狀況， 但卻沒有這方面的基礎，而也不是在金融界有經驗的人而設立的。我們的團隊從事對衝交易多年，在此期間取得了良好的業績和穩定的收益。爲了進一步發展和增加交易量，我們對外部投資有需求。我們從事廣泛的金融活動，主要集中在高收益公司和銀行本票上。這些都是相對少見的金融產品，很少有金融機構和個人可以使用。與其他高收益投資機會相比，公司和銀行本票是風險相當低的投資，因爲涉及這些期票的交易由主要銀行提供支持。我們建議您在互利的條件下成爲我們團隊的一員。與其他項目不同，我們不承諾以最低投資實現快速盈利。'
  } 
}