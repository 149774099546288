export default {
  contact: {
    p1: '知识赋予您力量',
    wrapper_title1: '按照您想要的方式和我们一起工作。',
    p2: '在环球星汇，我们会一直协助和指导您，尽我们所能帮助您迈向成功。我们的客户支援团队将每周5天，每天24小时为你提供服务。',
    info_title1: '投资者关系',
    info_span1: '欲咨询现有或潜在的有限合伙人/投资者的信息',
    info_title2: '公共关系',
    info_span2: '欲咨询新闻，演讲或营销相关的信息',
    info_title3: '商业计划书提交',
    info_span3: '欲提交商业计划',
    email_btn: '请电邮至： info@gcg-markets.com'
  }  
}