export default {
  contact: {
    p1: 'Knowledge empowers you',
    wrapper_title1: 'Work with us the way you want.',
    p2: 'At GCG MARKETS, we will always assist and guide you and do everything we can to help you succeed. Our customer support team will be available 24 hours a day, 5 days a week.',
    info_title1: 'Investor relations',
    info_span1: 'To consult with existing or potential limited partners/investors',
    info_title2: 'Public relations',
    info_span2: 'Ask for news, speaking or marketing related information',
    info_title3: 'Business plan submission',
    info_span3: 'To submit a business plan',
    email_btn: 'Please email: info@gcg-markets.com'
  }  
}